import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .screenshot-wrapper {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    .canvas-na {
      width: 400px;
      height: 295px;
      background: ${(props) => props.theme.darkBackground};
      border-radius: 5px;
      canvas {
        border-radius: 5px;
      }
    }
    .cam-status-badge {
      width: 90px;
      height: 30px;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      position: absolute;
      top: 20px;
      right: 20px;
      border-radius: 5px;
      .dot-status {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        opacity: 1;
        &.active {
          background: ${(props) => props.theme.onlineCamera};
        }
        &.inactive {
          background: ${(props) => props.theme.inactiveCamera};
        }
        &.offline {
          background: ${(props) => props.theme.offlineCamera};
        }
      }
      .status {
        font-family: 'Prompt-Medium', sans-serif;
        font-size: 10px;
        font-weight: normal;
        line-height: 1.25;
        color: white;
      }
    }
    .offline-cam-icon-wrapper {
      width: 32px;
      height: 28px;
      position: absolute;
      top: calc(50% - 14px);
      left: calc(50% - 16px);
    }
  }
`
