/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import CameraDetailScreenShotStyled from './styledComponent'
import * as PIXI from 'pixi.js'
import offLineCameraIcon from '../../asset/images/camera/camera-offline-cross.svg'
import defaultImg from '../../asset/images/default.png'

import withScreenshotBase64 from '../../utils/hoc/withScreenshotBase64'
import { CAMERA_OFFLINE } from '../../utils'

class CameraDetailScreenShot extends React.PureComponent {
  constructor(props) {
    super(props)
    let applicationStage = undefined
    let containerImage = undefined
    let imageSprite = undefined
    let containerShape = undefined
    let screenshotWidth = undefined
    let screenshotHeight = undefined
  }

  componentDidMount() {
    this.createApplicationStage()
    this.createImageContainer()
    this.generateScreenShotImage()
    this.createShapeContainer()
    if (this.props.cameraData.camera_status.toUpperCase() !== CAMERA_OFFLINE) {
      this.generateZone()
    }
    this.removePIXIConsole()
  }

  componentDidUpdate(prevProps, prevState) {
    this.reGenerateZone()
    this.handleStatusCameraChanged(prevProps)
  }

  componentWillUnmount() {
    this.applicationStage.destroy()
  }

  removePIXIConsole() {
    PIXI.utils.skipHello()
  }

  createApplicationStage() {
    const canvas = this.canvas
    this.applicationStage = new PIXI.Application(this.props.containerWidth, this.props.containerHeight, {
      autoResize: true,
      backgroundColor: 0x1f2d39,
      resolution: 1,
      antialias: true
    })
    canvas.appendChild(this.applicationStage.view)
  }

  createImageContainer() {
    this.containerImage = new PIXI.Container()
    this.applicationStage.stage.addChild(this.containerImage)
  }

  generateScreenShotImage() {
    const { cameraData } = this.props
    let texture
    const imageSrc = this.props.imgSrc ? this.props.imgSrc : defaultImg
    texture = PIXI.Texture.from(imageSrc)
    this.imageSprite = new PIXI.Sprite(texture)

    this.imageSprite.width = this.props.containerWidth
    this.imageSprite.height = this.props.containerHeight

    if (cameraData.camera_status.toUpperCase() === CAMERA_OFFLINE) {
      this.imageSprite.alpha = 0.2
      texture = PIXI.Texture.from(offLineCameraIcon)
    }
    if (texture.baseTexture.hasLoaded) {
      this.screenshotWidth = texture.width
      this.screenshotHeight = texture.height
      if (this.containerShape) {
        this.reGenerateZone()
      }
    } else {
      texture.on('update', () => {
        this.screenshotWidth = texture.width
        this.screenshotHeight = texture.height
        this.reGenerateZone()
      })
    }

    this.containerImage.addChild(this.imageSprite)
  }

  reGenerateScreenShotImage() {
    this.containerImage.removeChildren(0, this.containerImage.children.length)
    this.generateScreenShotImage()
  }

  createShapeContainer() {
    this.containerShape = new PIXI.Container()
    this.containerShape.interactive = true
    this.applicationStage.stage.addChild(this.containerShape)
  }

  handleStatusCameraChanged(prevProps) {
    if (prevProps.cameraData.camera_status !== this.props.cameraData.camera_status) {
      this.reGenerateScreenShotImage()
    }
  }

  generateZone() {
    const { coordinates } = this.props
    coordinates.forEach((polygonCoordinate, polygonIndex) => {
      const polygonGraphic = new PIXI.Graphics()
      polygonGraphic.beginFill(this.props.listGridColors[polygonIndex], 0.5)
      polygonGraphic.lineStyle(2, this.props.listGridColors[polygonIndex], 1)
      polygonGraphic.interactive = false
      const polygonGlobalCoordinates = polygonCoordinate.map((coordinatePoint) => {
        const tempX = (coordinatePoint.x * this.imageSprite.width) / this.props.imgResolutionWidth
        const tempY = (coordinatePoint.y * this.imageSprite.height) / this.props.imgResolutionHeight
        return new PIXI.Point(tempX, tempY)
      })
      polygonGraphic.drawPolygon(polygonGlobalCoordinates)
      polygonGraphic.endFill()
      this.containerShape.addChild(polygonGraphic)
    })
  }

  reGenerateZone() {
    this.containerShape.removeChildren(0, this.containerShape.children.length)
    if (this.props.cameraData.camera_status.toUpperCase() !== CAMERA_OFFLINE) {
      this.generateZone()
    }
  }

  renderCameraStatus() {
    let output = null
    const { cameraData } = this.props
    const status = cameraData.camera_status.toLowerCase()
    output = (
      <div className="cam-status-badge">
        <div className={'dot-status ' + status}>&nbsp;</div>
        <div className="status text-style">{cameraData.camera_status}</div>
      </div>
    )
    return output
  }

  renderOffLineCameraIcon() {
    let output = null
    const { cameraData } = this.props
    if (cameraData.camera_status.toUpperCase() === CAMERA_OFFLINE) {
      output = (
        <div className="offline-cam-icon-wrapper">
          <img src={offLineCameraIcon} alt="" width={32} height={28} />
        </div>
      )
    }
    return output
  }

  render() {
    return (
      <CameraDetailScreenShotStyled>
        <div className="screenshot-wrapper">
          <div
            className="canvas-na"
            id={`canvas-${this.props.cameraData.camera_id}`}
            ref={(cv) => (this.canvas = cv)}
            style={{ width: this.props.containerWidth, height: this.props.containerHeight }}
          />
          {this.props.showCameraStatuslabel && this.renderCameraStatus()}
          {this.props.showCameraStatusIcon && this.renderOffLineCameraIcon()}
        </div>
      </CameraDetailScreenShotStyled>
    )
  }
}

CameraDetailScreenShot.propTypes = {
  cameraData: PropTypes.shape({
    camera_id: PropTypes.number,
    camera_name: PropTypes.string,
    area_name: PropTypes.string,
    violation_count: PropTypes.number,
    camera_status: PropTypes.string
  }),
  coordinates: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number
      })
    )
  ),
  containerWidth: PropTypes.number.isRequired,
  containerHeight: PropTypes.number.isRequired,
  showCameraStatusIcon: PropTypes.bool,
  showCameraStatuslabel: PropTypes.bool,
  imageSrc: PropTypes.string,
  imgResolutionWidth: PropTypes.number.isRequired,
  imgResolutionHeight: PropTypes.number.isRequired,
  listGridColors: PropTypes.arrayOf(PropTypes.string)
}

export default withScreenshotBase64(CameraDetailScreenShot)
